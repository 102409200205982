import { useCallback, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  PASTE_COMMAND,
  $createParagraphNode,
} from "lexical";
import { $createImageNode, setupImageNodeDeletion } from "./EditableImageNode";
import { generateFileName } from "../../../lib/common";

function ClipboardImagePlugin({ onImagePasted }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    setupImageNodeDeletion(editor);
  }, []);

  const handlePaste = useCallback(
    (event) => {
      const clipboardData = event.clipboardData;

      if (!clipboardData) return false;

      // Check for image types
      const items = clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        // Only process image files
        if (item.type.startsWith("image/")) {
          event.preventDefault(); // Prevent default paste behavior

          const file = item.getAsFile();
          if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const fileBase64 = e.target.result;
              editor.update(() => {
                const selection = $getSelection();
                // Ensure we have a valid range selection
                if ($isRangeSelection(selection)) {
                  // Create image node
                  const imageNode = $createImageNode(
                    fileBase64,
                    generateFileName(file.type, file.size),
                    "inherit",
                    "inherit"
                  );
                  // Create a paragraph to wrap the image
                  const paragraphNode = $createParagraphNode();
                  paragraphNode.append(imageNode);
                  // Insert the paragraph
                  selection.insertNodes([paragraphNode]);
                  onImagePasted(file, fileBase64);
                }
              });
            };
            // Read the file as data URL
            reader.readAsDataURL(file);
            break; // Only paste the first image
          }
        }
      }

      return false;
    },
    [editor]
  );

  // Register the paste command with low priority
  editor.registerCommand(PASTE_COMMAND, handlePaste, COMMAND_PRIORITY_LOW);

  return null;
}

export default ClipboardImagePlugin;
