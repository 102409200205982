import { useState } from "react";
import {
  CompoundButton,
  Image,
  Text,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { getHumanDate } from "../../../lib/common";

export const MyPastList = ({
  list,
  onSelectRow,
  groups,
  groupsCollapse,
  onGroupCollapse,
  groupType,
  isAllDeselected,
}) => {
  const [openAcordions, setOpenAcordions] = useState(groupsCollapse);
  const [selectedItem, setSelectedItem] = useState();

  const handleAccordionToggle = (event, data) => {
    setOpenAcordions(data.openItems);
    onGroupCollapse(data.openItems.includes(data.value), data.value);
  };

  const getProcessedDetailsLine = (item) => {
    let line = `${item.status === "On-hold" ? "ON HOLD・" : ""} ${item.case_id}`;
    if (item.team_name) {
      line += `・${item.team_name}`;
    }
    if (item.assigned_name) {
      line += `/${item.assigned_name}`;
    }
    return (
      <Text align="start" style={{ alignItems: "center", display: "flex", fontSize: "12px" }}>
        {line}
        <br></br>
        {item.status === "Completed" ? "Closed" : "Canceled"}: {getHumanDate(item.updated_at)}
      </Text>
    );
  };

  return (
    <div style={{ display: list && list.length > 0 ? "flex" : "none" }}>
      <Accordion
        openItems={openAcordions}
        onToggle={handleAccordionToggle}
        multiple
        collapsible
        className="scrollable-content"
        style={{
          maxHeight: "600px",
          width: "100%",
        }}
      >
        {groups.map((defaultState) => (
          <div key={defaultState}>
            {list.some((i) => i[groupType] === defaultState) && (
              <AccordionItem value={defaultState}>
                <AccordionHeader>
                  <span className="accordion-header">{defaultState}</span>
                </AccordionHeader>
                <AccordionPanel>
                  <div className="accordion-container">
                    {list
                      .filter((i) => i[groupType] === defaultState)
                      .map((item) => (
                        <CompoundButton
                          key={item.case_id}
                          secondaryContent={getProcessedDetailsLine(item)}
                          appearance="subtle"
                          size="medium"
                          shape="rounded"
                          className="accordion-action"
                          style={
                            isAllDeselected || selectedItem !== item.case_id
                              ? {}
                              : { background: "var(--colorSubtleBackgroundSelected)" }
                          }
                          icon={
                            <Image
                              src={`/icons/${item.classification}${item.status}48.png`}
                              style={{ width: "24px", alignSelf: "flex-start" }}
                            />
                          }
                          onClick={() => {
                            setSelectedItem(item.case_id);
                            onSelectRow(item);
                          }}
                        >
                          {item.subject}
                        </CompoundButton>
                      ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            )}
          </div>
        ))}
      </Accordion>
    </div>
  );
};
