import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Field,
  Input,
  Textarea,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  Tag,
  Avatar,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Spinner,
} from "@fluentui/react-components";
import { debounce } from "lodash";
import { MessageGroup } from "../../MessageGroup";
import { AnimatedDots } from "../../AnimatedDots";
import { TeamsFxContext } from "../../../Context";
import useAxios from "../../../lib/useAxios";
import {
  generateRandomId,
  capitalizeFirstLetter,
  getAuthEmail,
  extractTextFromJSON,
} from "../../../lib/common";
import { useGraphApiCall } from "../../../lib/useGraphApiCall";
import * as microsoftTeams from "@microsoft/teams-js";

export const CreateGroupChatDialog = ({ isOpen, incident, onClose }) => {
  const authenticatedUserEmail = getAuthEmail();
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);
  const { makeCall, isLoading } = useGraphApiCall();

  const [messages, setMessages] = useState([]);
  const [ssoToken, setSsoToken] = useState();

  const defaultTopic = `${capitalizeFirstLetter(incident.classification)} ~ ${
    incident.subject
  } • #${incident.case_id}`;
  const [chatTopic, setChatTopic] = useState(defaultTopic);
  const defaultInitialMessage = incident.summary
    ? incident.summary
    : extractTextFromJSON(incident.raw_content);
  const [initialMessage, setInitialMessage] = useState(defaultInitialMessage);

  const [typing, setTyping] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);

  const defaultParticipants = [
    incident.assigned_msid && {
      display_name: incident.assigned_name,
      email: incident.assigned_email,
      ms_id: incident.assigned_msid,
    },
    {
      display_name: incident.requester_name,
      email: incident.requester_email,
      ms_id: incident.requester_msid,
    },
  ].filter(Boolean);

  const [selectedParticipants, setSelectedParticipants] = useState(defaultParticipants);
  const [showErrorTopic, setShowErrorTopic] = useState(false);
  const [showErrorParticipants, setErrorParticipants] = useState();
  const [creatingGroupChat, setCreatingGroupChat] = useState(false);

  useEffect(() => {
    const validateCurrentParticipant = async () => {
      if (!defaultParticipants.some((u) => u.email === authenticatedUserEmail)) {
        try {
          const { data: currentUser } = await axiosUserInstance.get("/org/me");
          setSelectedParticipants((prev) => [...prev, currentUser]);
        } catch (err) {
          console.debug(err);
        }
      }
    };
    validateCurrentParticipant();
  }, []);

  const getOrganizationUsers = debounce(async (contain) => {
    try {
      await makeCall({
        url: `/msteams/delegated/users?contain=${contain}`,
        method: "get",
        scopes: ["User.ReadBasic.All", "Chat.Create", "Chat.ReadWrite"],
        onSuccess: (response, token) => {
          setOrganizationUsers(response);
          if (!ssoToken) {
            setSsoToken(token);
          }
        },
        onError: (error) => {
          console.error("Error getting users:", error);
        },
        ssoToken: ssoToken,
      });
    } catch (error) {
      console.error(error);
    }
  }, 500);

  const handleStartGroupChat = async () => {
    let isValidForm = true;
    if (chatTopic.trim().length === 0) {
      setShowErrorTopic(true);
      isValidForm = false;
    }
    if (!selectedParticipants.some((u) => u.email === authenticatedUserEmail)) {
      setErrorParticipants(
        "You must include yourself as a participant in any group chat you create"
      );
      isValidForm = false;
    } else if (selectedParticipants.length < 2) {
      setErrorParticipants("Select at least two participants to start a group chat");
      isValidForm = false;
    }
    if (isValidForm) {
      try {
        setCreatingGroupChat(true);
        await makeCall({
          url: "/msteams/delegated/group-chat",
          method: "post",
          scopes: ["Chat.Create", "Chat.ReadWrite"],
          payload: {
            topic: chatTopic,
            members: selectedParticipants.map((p) => p.ms_id),
            initial_message: initialMessage || null,
            metadata: [
              { parameter: "Case ID", value: incident.case_id },
              { parameter: "Subject", value: incident.subject },
              { parameter: "Requester", value: incident.requester_name },
            ],
          },
          onSuccess: (response, token) => {
            if (response) {
              microsoftTeams.app
                .initialize()
                .then(() => {
                  setTimeout(() => {
                    setCreatingGroupChat(false);
                    microsoftTeams.app.openLink(
                      `https://teams.microsoft.com/l/chat/${response.chat_id}/0`
                    );
                  }, 3000);
                })
                .catch((error) => {
                  console.error("Error al inicializar Teams SDK:", error);
                });
            }
          },
          onError: (error) => {
            console.error("Error creating group chat:", error);
            const errorMessage = error.message || "An unexpected error occurred";
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: "error",
                title: "Upps",
                body: `Unable to create the group chat. ${errorMessage}. Please try again later.`,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.id !== msgId)),
              },
              ...prevMessages,
            ]);
            setCreatingGroupChat(false);
          },
          ssoToken: ssoToken,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const userSearchResult = organizationUsers.filter(
    (option) => !selectedParticipants.some((teammate) => teammate.email === option.email)
  );

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Start a group chat for this {incident.classification}</DialogTitle>
          <DialogContent>
            <MessageGroup messages={messages} />
            <div style={{ marginBottom: "2em", marginTop: "1em", display: "grid", gap: "0.5em" }}>
              <Field
                label="Chat topic"
                required
                validationMessage={
                  showErrorTopic ? "Specify a topic to identify the group chat" : ""
                }
              >
                <Input
                  placeholder="Group chat topic"
                  value={chatTopic}
                  onChange={(event) => {
                    setChatTopic(event.target.value);
                    if (showErrorTopic && event.target.value.trim().length > 0)
                      setShowErrorTopic(false);
                  }}
                />
              </Field>
              <Field label="Participants" required validationMessage={showErrorParticipants}>
                <TagPicker
                  onOptionSelect={(e, data) => {
                    setTyping(false);
                    setSelectedParticipants(data.selectedOptions);
                    if (showErrorParticipants) setErrorParticipants(undefined);
                  }}
                  selectedOptions={selectedParticipants}
                >
                  <TagPickerControl
                    expandIcon={isLoading && !creatingGroupChat ? <Spinner size="tiny" /> : null}
                  >
                    <TagPickerGroup>
                      {selectedParticipants.map((teammate) => (
                        <Tag
                          key={teammate.email}
                          shape="circular"
                          media={
                            <Avatar aria-hidden name={teammate.display_name} color="colorful" />
                          }
                          value={teammate}
                        >
                          {teammate.display_name}
                        </Tag>
                      ))}
                    </TagPickerGroup>
                    <TagPickerInput
                      aria-label="Enter participan's name"
                      placeholder={selectedParticipants.length > 0 ? "" : "Enter participan's name"}
                      onChange={(e) => {
                        setTyping(Boolean(e.target.value));
                        getOrganizationUsers(e.target.value);
                      }}
                      onBlur={(e) => setTyping(false)}
                    />
                  </TagPickerControl>
                  <TagPickerList>
                    {userSearchResult.length > 0
                      ? userSearchResult.map((user) => (
                          <TagPickerOption
                            media={
                              <Avatar
                                shape="circular"
                                aria-hidden
                                name={user.display_name}
                                color="colorful"
                              />
                            }
                            value={user}
                            key={user.email}
                          >
                            {user.display_name}
                          </TagPickerOption>
                        ))
                      : typing
                      ? isLoading || "We couldn't find any matches"
                      : "Enter participan's name"}
                  </TagPickerList>
                </TagPicker>
              </Field>
              <Field className="mb-1" label="Case details (to include in the initial message)">
                <Textarea
                  value={initialMessage}
                  maxLength={8000}
                  resize="vertical"
                  rows={5}
                  placeholder="Case details for the group chat (optional)"
                  onChange={(event) => setInitialMessage(event.target.value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              onClick={() => onClose(false)}
              disabled={creatingGroupChat}
            >
              Cancel
            </Button>
            <Button
              appearance={creatingGroupChat ? "transparent" : "primary"}
              onClick={handleStartGroupChat}
              disabled={creatingGroupChat}
            >
              <div style={{ width: "8em", display: "flex", justifyContent: "center" }}>
                {creatingGroupChat ? (
                  <>
                    <span>Creating group</span>
                    <AnimatedDots />
                  </>
                ) : (
                  "Start group chat"
                )}
              </div>
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
