import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useState, useContext } from "react";
import {
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavItem,
  NavSectionHeader,
  NavSubItem,
  NavSubItemGroup,
  Hamburger,
} from "@fluentui/react-nav-preview";
import { Tooltip } from "@fluentui/react-components";

import {
  Board20Filled,
  Board20Regular,
  Building20Filled,
  Building20Regular,
  PeopleCommunity20Filled,
  PeopleCommunity20Regular,
  Receipt20Filled,
  Receipt20Regular,
  ScreenSearch20Filled,
  ScreenSearch20Regular,
  TagMultiple20Regular,
  TagMultiple20Filled,
  LocationRipple20Regular,
  LocationRipple20Filled,
  bundleIcon,
} from "@fluentui/react-icons";
import { TeamsFxContext } from "../../Context";
import { haveRole } from "../../lib/common";
import { Dashboard } from "./Dashboard";
import { Incidents } from "./Incidents";
import { Teams } from "./Teams";
import { Tags } from "./Tags";
import { Organization } from "./Organization";
import { Subscription } from "./Subscription";
import { BillingHistory } from "./BillingHistory";

const DashboardIcon = bundleIcon(Board20Filled, Board20Regular);
const IncidentsSearch = bundleIcon(ScreenSearch20Filled, ScreenSearch20Regular);
const OrganizationIcon = bundleIcon(Building20Filled, Building20Regular);
const TeamsIcon = bundleIcon(PeopleCommunity20Filled, PeopleCommunity20Regular);
const LocationsIcon = bundleIcon(LocationRipple20Filled, LocationRipple20Regular);
const TagsIcon = bundleIcon(TagMultiple20Filled, TagMultiple20Regular);
const CareerDevelopment = bundleIcon(Receipt20Filled, Receipt20Regular);

export function AdminScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace("/tab/", "");
  const { organizationName, usingMobileDevice } = useContext(TeamsFxContext);

  const [isOpenMenu, setIsOpenMenu] = useState(!usingMobileDevice);

  const handleNavItemSelect = (e, data) => {
    navigate(data.value);
    if (usingMobileDevice) {
      setIsOpenMenu(false);
    }
  };

  const renderHamburgerWithToolTip = () => {
    return (
      <Tooltip content="Navigation" relationship="label">
        <Hamburger onClick={() => setIsOpenMenu(!isOpenMenu)} />
      </Tooltip>
    );
  };

  const isAdminOwner = haveRole("owner");

  return (
    <div className="layout-container">
      <div className={`navbar-container ${isOpenMenu ? "navbar-container-full" : ""}`}>
        <NavDrawer
          selectedValue={currentPath}
          open={isOpenMenu}
          type="inline"
          onNavItemSelect={handleNavItemSelect}
        >
          <NavDrawerHeader>
            {renderHamburgerWithToolTip()}
            <b style={{ padding: "0.4em" }}>{organizationName}</b>
          </NavDrawerHeader>
          <NavDrawerBody>
            <div className="scrollable-content">
              <NavItem icon={<IncidentsSearch />} value="all_incidents">
                Incidents
              </NavItem>
              <NavItem icon={<DashboardIcon />} value="dash">
                Dashboard
              </NavItem>
              <NavSectionHeader>Organization Setup</NavSectionHeader>
              <NavItem icon={<TeamsIcon />} value="setup_teams">
                Technician Teams
              </NavItem>
              {/* <NavItem icon={<LocationsIcon />} value="setup_locations">
            Locations
          </NavItem> */}
              <NavItem icon={<TagsIcon />} value="setup_tags">
                Tags
              </NavItem>
              {isAdminOwner && (
                <>
                  <NavItem icon={<OrganizationIcon />} value="setup_organization">
                    Organization
                  </NavItem>
                  {/* <NavDivider /> */}
                  {!usingMobileDevice && (
                    <NavCategory value="subscription">
                      <NavCategoryItem icon={<CareerDevelopment />}>Subscription</NavCategoryItem>
                      <NavSubItemGroup>
                        <NavSubItem value="manage_subscription">Billing & Usage</NavSubItem>
                        {/* <NavSubItem value="billing_history">Billing History</NavSubItem> */}
                      </NavSubItemGroup>
                    </NavCategory>
                  )}
                </>
              )}
            </div>
          </NavDrawerBody>
        </NavDrawer>
        {!isOpenMenu && renderHamburgerWithToolTip()}
      </div>
      {usingMobileDevice && isOpenMenu && (
        <div className="content-overlay" onClick={() => setIsOpenMenu(false)} />
      )}
      <div className="admin-container">
        <div className="scrollable-content" style={{ overflowX: "hidden" }}>
          <Routes>
            <Route path="all_incidents" element={<Incidents />} />
            <Route path="dash" element={<Dashboard />} />
            <Route path="setup_teams" element={<Teams />} />
            <Route path="setup_tags" element={<Tags />} />
            <Route path="setup_organization" element={<Organization />} />
            <Route path="manage_subscription" element={<Subscription />} />
            <Route path="billing_history" element={<BillingHistory />} />
            <Route path="*" element={<Navigate to="all_incidents" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
