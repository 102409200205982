import { useState, useEffect } from "react";

export const AnimatedDots = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === ".") return "..";
        if (prevDots === "..") return "...";
        return ".";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <span style={{ width: "1em" }}>{dots}</span>;
};
