import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Caption1, Persona } from "@fluentui/react-components";
import {
  MegaphoneLoud16Regular as CreatedIcon,
  PeopleCheckmark16Regular as AssignedIcon,
  PeopleSwap16Regular as ReassignedIcon,
  Incognito20Regular as UnassignedIcon,
  Pause20Regular as PausedIcon,
  Prohibited16Regular as CanceledIcon,
  ArchiveArrowBack16Regular as ReopenIcon,
  NextFrame20Filled as ContinueIcon,
  Checkmark16Filled as CompletedIcon,
  AlertOn16Regular as PriorityIcon,
} from "@fluentui/react-icons";
import { getFullDateTime } from "../../lib/common";
import TagContainer from "../../components/TagContainer/TagContainer";

const ACTION_ICONS = {
  created: <CreatedIcon />,
  assigned: <AssignedIcon />,
  reopen: <ReopenIcon />,
  reassigned: <ReassignedIcon />,
  unassigned: <UnassignedIcon />,
  paused: <PausedIcon />,
  canceled: <CanceledIcon />,
  resumed: <ContinueIcon />,
  completed: <CompletedIcon />,
  priority_change: <PriorityIcon />,
};

const ACTION_COLORS = {
  created: "var(--colorPaletteBlueBorderActive)",
  assigned: "var(--colorBrandBackground3Static)",
  reopen: "var(--colorPaletteBlueBorderActive)",
  reassigned: "var(--colorPaletteBerryBackground3)",
  unassigned: "var(--colorNeutralBackground1Hover)",
  paused: "var(--colorNeutralStroke1Pressed)",
  status_change: "var(--colorNeutralStroke1Pressed)",
  canceled: "var(--colorPaletteCranberryBorderActive)",
  resumed: "var(--colorPaletteBlueBorderActive)",
  completed: "var(--colorPaletteGreenForegroundInverted)",
  priority_change: "var(--colorPalettePumpkinBorderActive)",
};

const FormattedText = ({ text }) => {
  const formatText = (inputText) => {
    if (!inputText) {
      return "";
    }

    try {
      const pattern = /^(.*?)<(.*?)>\/(.*)$/;
      const match = inputText.match(pattern);

      if (match) {
        const [_, prefix, team, user] = match;
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Caption1 style={{ marginBottom: "1em" }}>{prefix.trim()}:</Caption1>
            <Persona
              size="medium"
              name={user}
              avatar={{ color: "colorful" }}
              secondaryText={team}
            />
          </div>
        );
      }
      return inputText;
    } catch (error) {
      return inputText || "";
    }
  };

  return <p>{formatText(text)}</p>;
};

const Timeline = ({ history }) => {
  return (
    <VerticalTimeline layout="1-column-left" lineColor="#dedede">
      {history.map((historyItem) => (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "var(--colorNeutralBackground1Hover)",
            color: "var(--colorNeutralForeground1)",
            maxWidth: "40rem",
          }}
          contentArrowStyle={{ borderRight: "7px solid  var(--colorNeutralBackground1Hover)" }}
          date={getFullDateTime(historyItem.change_date)}
          iconStyle={{
            background: ACTION_COLORS[historyItem.change_action],
            color: "var(--colorNeutralForegroundStaticInverted)",
          }}
          icon={historyItem.change_action && ACTION_ICONS[historyItem.change_action]}
        >
          <h3 className="vertical-timeline-element-title">{historyItem.change_desc}</h3>
          <h4
            className="vertical-timeline-element-subtitle"
            style={{ color: "var(--colorBrandForegroundLink)" }}
          >
            {`by: ${historyItem.by_user_name}`}
          </h4>
          <FormattedText text={historyItem.change_summary} />
          {historyItem.change_metadata && (
            <div className="mt-6 flex-column">
              <Caption1 className="mb-4">{historyItem.change_metadata.tag_title}:</Caption1>
              <TagContainer tags={historyItem.change_metadata.tags} />
            </div>
          )}
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default Timeline;
