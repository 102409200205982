import React, { useEffect } from "react";
import { DecoratorNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

// 1. Create a custom ImageDisplayNode class that extends DecoratorNode
export class ImageDisplayNode extends DecoratorNode {
  __src;
  __alt;
  __width;
  __height;
  __caption;

  static getType() {
    return "image";
  }

  static clone(node) {
    return new ImageDisplayNode(
      node.__src,
      node.__alt,
      node.__width,
      node.__height,
      node.__caption
    );
  }

  static importJSON(serializedNode) {
    const { src, alt, width, height, caption } = serializedNode;
    return new ImageDisplayNode(src, alt, width, height, caption);
  }

  constructor(src, alt, width, height, caption) {
    super();
    this.__src = src;
    this.__alt = alt;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
    this.__caption = caption;
  }

  createDOM() {
    const div = document.createElement("div");
    div.className = "image-container";
    return div;
  }

  updateDOM() {
    return false;
  }

  exportJSON() {
    return {
      type: "image",
      src: this.__src,
      alt: this.__alt,
      width: this.__width,
      height: this.__height,
      caption: this.__caption,
      version: 1,
    };
  }

  getSrc() {
    return this.__src;
  }

  getAlt() {
    return this.__alt;
  }

  getWidth() {
    return this.__width;
  }

  getHeight() {
    return this.__height;
  }

  decorate() {
    return (
      <ImageComponent
        src={this.__src}
        alt={this.__alt}
        width={this.__width}
        height={this.__height}
      />
    );
  }
}

// 2. Create ImageComponent to render the image
function ImageComponent({ src, alt, width, height }) {
  return (
    <div className="image-wrapper">
      <img
        src={src}
        alt={alt || ""}
        style={{
          width: width === "inherit" ? "100%" : width,
          height: height === "inherit" ? "auto" : height,
          maxWidth: "100%",
          display: "block",
          margin: "10px 0",
        }}
      />
    </div>
  );
}

// 3. Function to transform Lexical JSON content to support images
export function transformLexicalContent(content) {
  if (!content) return content;

  // Parse the content if it's a string
  const parsedContent = typeof content === "string" ? JSON.parse(content) : content;

  // Define a recursive function to process nodes
  function processNode(node) {
    if (!node || typeof node !== "object") return node;

    // Process image nodes
    if (node.type === "image") {
      // Make sure image node structure is complete
      return {
        ...node,
        children: node.children || [],
      };
    }

    // Process child nodes if they exist
    if (Array.isArray(node.children)) {
      return {
        ...node,
        children: node.children.map((child) => processNode(child)),
      };
    }

    return node;
  }

  // Process the entire content tree
  return {
    ...parsedContent,
    root: processNode(parsedContent.root),
  };
}

// 4. Create ImageDisplayNode Plugin to register node transformers
export function ImageDisplayNodePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageDisplayNode])) {
      throw new Error("ImageNodePlugin: ImageDisplayNode not registered on editor");
    }

    // Register deserializer for image nodes
    return editor.registerNodeTransform(ImageDisplayNode, (node) => {
      // This transform ensures images render correctly
      // You can add additional transforms if needed
    });
  }, [editor]);

  return null;
}

// 7. Component to handle image node deserialization
export function ImageNodeDeserializer() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeTransform = editor.registerNodeTransform(ImageDisplayNode, (node) => {
      // This ensures the image node is properly rendered
      const src = node.getSrc();
      const alt = node.getAlt();
      const width = node.getWidth();
      const height = node.getHeight();

      // We could add more transformations here if needed
      // For example, handling image loading errors, etc.
    });

    return () => {
      removeTransform();
    };
  }, [editor]);

  return null;
}
