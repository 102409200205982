import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AnnouncementSmall from "../../../../components/AnnouncementSmall";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import {
  Card,
  CardHeader,
  CardFooter,
  Link,
  Body1,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItemRadio,
  MenuGroupHeader,
  MenuGroup,
  MenuDivider,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import Plot from "react-plotly.js";
import { MessageBar } from "../../../../components/MessageBar";

const SunburstChart = ({ theme = "dark", width = "30" }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState({ period: ["90"] });
  const [selectedStatus, setSelectedStatus] = useState({ status: ["all"] });
  const [disablePeriod, setDisablePeriod] = useState(false);
  const [haveUncategorizedTags, setHaveUncategorizedTags] = useState(false);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCasesTeamsData(selectedStatus.status, selectedPeriod.period);
  }, [selectedPeriod, selectedStatus]);

  const getCasesTeamsData = async (status, period) => {
    try {
      const { data } = await axiosInstance.get(
        `/dash/categories?status=${status}&period=${period}`
      );
      setChartData(data);
      if (data.some((c) => c.category === "[Uncategorized]")) {
        setHaveUncategorizedTags(true);
      } else if (haveUncategorizedTags) {
        setHaveUncategorizedTags(false);
      }
    } catch (e) {
      console.error("Error loading incidents chart:", e);
    } finally {
      setLoading(false);
    }
  };

  const defaultLayout = {
    font: { size: 12 },
    margin: { l: 0, r: 0, b: 0, t: 0 },
    autosize: true,
    sunburstcolorway: ["default", "light"].includes(theme)
      ? ["#3949ab", "#ff9800", "#5cddd1"]
      : ["#3f51b5", "#ffc107", "#00cc96"],
    paper_bgcolor: "transparent",
  };

  const convertToSunburstFormat = (data) => {
    // Inicializamos los arrays para el formato sunburst
    const labels = ["Categories"]; // El nodo raíz
    const parents = [""]; // El nodo raíz no tiene padre
    const values = [0]; // Inicializamos en 0, luego sumaremos todos los casos

    // Iteramos sobre las categorías
    data.forEach((category) => {
      // Agregamos la categoría
      labels.push(category.category.replace(/\s+(?=&|\w{4,})/g, "<br>"));
      parents.push("Categories"); // Su padre es el nodo raíz "Categories"

      // Calculamos la suma de casos para esta categoría
      const categoryTotal = category.tags.reduce((sum, tag) => sum + tag.cases, 0);
      values.push(categoryTotal);

      // Iteramos sobre los tags de la categoría
      category.tags.forEach((tag) => {
        labels.push(tag.name.replace(/\s+(?=&|\w{4,})/g, "<br>"));
        parents.push(category.category.replace(/\s+(?=&|\w{4,})/g, "<br>"));
        values.push(tag.cases);
      });
    });

    // Calculamos el total para el nodo raíz (suma de todos los casos)
    values[0] = values.reduce((sum, value, index) => (index === 0 ? sum : sum + value), 0);

    return [
      {
        type: "sunburst",
        labels,
        parents,
        values,
        outsidetextfont: {
          size: 14,
          color: ["default", "light"].includes(theme) ? "#242424" : "white",
        },
        leaf: { opacity: 0.8 },
        marker: { line: { width: 2 } },
        /* branchvalues: "total", */
        textposition: "inside",
        /* insidetextorientation: "radial", */
      },
    ];
  };

  const sunburstData = useMemo(() => {
    try {
      return convertToSunburstFormat(chartData);
    } catch (error) {
      console.error("Error converting data for tags chart:", error);
      return null;
    }
  }, [chartData, theme]);

  if (!sunburstData) {
    return <div>Error loading chart data</div>;
  }

  const onSelectPeriod = (e, { name, checkedItems }) => {
    setSelectedPeriod((s) => ({ ...s, [name]: checkedItems }));
  };

  const onSelectStatus = (e, { name, checkedItems }) => {
    setSelectedStatus((s) => ({ ...s, [name]: checkedItems }));
    if (checkedItems.length === 1 && checkedItems[0] === "open") {
      setDisablePeriod(true);
    } else if (disablePeriod) {
      setDisablePeriod(false);
    }
  };

  return (
    <Card className={`dashboard-card card-${width}`}>
      <CardHeader
        header={
          <Body1>
            <b>Incident classification</b>
          </Body1>
        }
        action={
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="transparent" icon={<MoreHorizontal20Regular />}></MenuButton>
            </MenuTrigger>
            <MenuPopover>
              <MenuGroup>
                <MenuGroupHeader>Period</MenuGroupHeader>
                <MenuList checkedValues={selectedPeriod} onCheckedValueChange={onSelectPeriod}>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="7">
                    Last 7 Days
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="30">
                    Last 30 Days
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="90">
                    Last 90 Days
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="ytd">
                    Year to Date (YTD)
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="1826">
                    Last 5 Years
                  </MenuItemRadio>
                </MenuList>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>Incident status</MenuGroupHeader>
                <MenuList checkedValues={selectedStatus} onCheckedValueChange={onSelectStatus}>
                  <MenuItemRadio name="status" value="open">
                    Open
                  </MenuItemRadio>
                  <MenuItemRadio name="status" value="closed">
                    Closed
                  </MenuItemRadio>
                  <MenuItemRadio name="status" value="all">
                    All
                  </MenuItemRadio>
                </MenuList>
              </MenuGroup>
            </MenuPopover>
          </Menu>
        }
      />

      <div style={{ width: "100%", maxHeight: "350px", minHeight: "300px" }}>
        {!loading &&
          (chartData.length === 0 ? (
            <AnnouncementSmall
              imgSrc="dashboard.png"
              imgAlt="No classification data available"
              imgWidth="15em"
              title="No classification data available"
              subtitle="Come back later or update filtering"
            />
          ) : (
            <Plot
              data={sunburstData}
              style={{
                width: "100%",
                height: "100%",
              }}
              config={{ displayModeBar: false, responsive: true }}
              layout={defaultLayout}
            />
          ))}
      </div>
      {haveUncategorizedTags && (
        <CardFooter style={{ zIndex: "10" }}>
          <MessageBar
            intent="info"
            title="Uncategorized tags found."
            body={
              <>
                Visit the{" "}
                <Link className="link" onClick={() => navigate("../setup_tags")}>
                  Tags
                </Link>{" "}
                section to categorize them or merge any duplicates.
              </>
            }
          />
        </CardFooter>
      )}
    </Card>
  );
};

export default SunburstChart;
