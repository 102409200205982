import { useRef } from "react";
import {
  Persona,
  Body2,
  Caption1,
  Caption2,
  createTableColumn,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Image,
  Tooltip,
  TableCellLayout,
} from "@fluentui/react-components";
import { CircleFilled, Attach16Regular } from "@fluentui/react-icons";
import { getHumanDate } from "../../../../lib/common";

const columnSizingOptions = {
  subject: {
    minWidth: 120,
    defaultWidth: 380,
  },
  requested: {
    minWidth: 190,
    defaultWidth: 210,
  },
  assigned: {
    minWidth: 220,
    defaultWidth: 240,
  },
  id: {
    minWidth: 90,
    defaultWidth: 110,
  },
  priority: {
    minWidth: 100,
    defaultWidth: 180,
  },
  lastUpdated: {
    minWidth: 120,
    defaultWidth: 180,
  },
};

const fullColumns = [
  createTableColumn({
    columnId: "subject",
    compare: (a, b) => {
      return a.subject.localeCompare(b.subject);
    },
    renderHeaderCell: () => {
      return "Subject";
    },
    renderCell: (item) => {
      return (
        <div style={{ display: "flex", alignItems: "center", paddingBlock: "0.5rem" }}>
          {/* <CircleFilled className={`dot-priority dot-priority-${item.priority}`} /> */}
          <Image
            src={`/icons/${item.classification}${item.status}48.png`}
            style={{ width: "20px", marginRight: "0.5rem" }}
          />
          <Tooltip
            content={
              <span className="first-letter">
                {item.classification}
                {" #"}
                {item.case_id}{" "}
                <span
                  shape="circular"
                  size="small"
                  appearance="brand"
                  className={`tag-status-${item.status} pill-tag`}
                >
                  {item.status}
                </span>{" "}
              </span>
            }
            withArrow={true}
            relationship="label"
          >
            <TableCellLayout>
              <span className={`${item.status.toLowerCase()}-text`}>{item.subject}</span>
            </TableCellLayout>
          </Tooltip>
        </div>
      );
    },
    sizingOptions: columnSizingOptions.subject,
  }),
  createTableColumn({
    columnId: "requested",
    compare: (a, b) => {
      return a.requester_name?.localeCompare(b.requester_name);
    },
    renderHeaderCell: () => {
      return "Requested by";
    },
    renderCell: (item) => {
      return (
        <Tooltip content={item.requester_email} withArrow={true} relationship="label">
          <TableCellLayout>
            <Persona
              size="medium"
              name={item.requester_name}
              avatar={{ color: "colorful" }}
              secondaryText={`@${item.requester_email?.split("@")[0]}`}
            />
          </TableCellLayout>
        </Tooltip>
      );
    },
    sizingOptions: columnSizingOptions.requested,
  }),
  createTableColumn({
    columnId: "assigned",
    compare: (a, b) => {
      return a.assigned_name?.localeCompare(b.assigned_name);
    },
    renderHeaderCell: () => {
      return "Assigned to";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          <Persona
            size="medium"
            name={item.assigned_name || "Unassigned"}
            avatar={{ color: "colorful" }}
            secondaryText={item.team_name || "-"}
          />
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.assigned,
  }),
  createTableColumn({
    columnId: "id",
    compare: (a, b) => {
      return a.case_id.localeCompare(b.case_id);
    },
    renderHeaderCell: () => {
      return "Case #";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout className="caseId">
          {item.case_id}
          {item.have_attachment && <Attach16Regular className="attachmentIconItem" />}
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.id,
  }),
  createTableColumn({
    columnId: "priority",
    compare: (a, b) => {
      return a.priority.localeCompare(b.priority);
    },
    renderHeaderCell: () => {
      return "Priority";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircleFilled className={`dot-priority dot-priority-${item.priority}`} />
            <span>{item.priority}</span>
          </div>
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.priority,
  }),
  createTableColumn({
    columnId: "lastUpdated",
    compare: (a, b) => {
      return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
    },
    renderHeaderCell: () => {
      return "Last updated";
    },
    renderCell: (item) => {
      return getHumanDate(item.updated_at);
    },
    sizingOptions: columnSizingOptions.lastUpdated,
  }),
];

const compactColumns = [
  createTableColumn({
    columnId: "subject",
    compare: (a, b) => {
      return a.subject.localeCompare(b.subject);
    },
    renderHeaderCell: () => {
      return "Issue";
    },
    renderCell: (item) => {
      return (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              paddingBlock: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            <TableCellLayout>
              <Body2>{item.subject}</Body2>
            </TableCellLayout>
          </div>
          <div className="incident-details-list-item">
            <div className="items-detail">
              <div className="item">
                <Caption2 style={{ marginBottom: "1em" }}>Requested by</Caption2>
                <Persona
                  size="extra-small"
                  name={item.requester_name}
                  avatar={{ color: "colorful" }}
                  secondaryText={`@${item.requester_email?.split("@")[0]}`}
                />
              </div>

              {item.assigned_name && (
                <>
                  <div className="item">
                    <Caption2 style={{ marginBottom: "1em" }}>Assigned to</Caption2>
                    <Persona
                      size="extra-small"
                      name={item.assigned_name || "Unassigned"}
                      avatar={{ color: "colorful" }}
                      secondaryText={item.team_name || ""}
                    />
                  </div>

                  {item.priority && (
                    <div className="item">
                      <Caption2 style={{ marginBottom: "1em" }}>Priority</Caption2>
                      <div className="flex-column">
                        <div className="horizontal-content">
                          <CircleFilled className={`dot-priority dot-priority-${item.priority}`} />
                          <Caption1>{item.priority}</Caption1>
                        </div>
                        <div className="horizontal-content">
                          <Image
                            src={`/icons/${item.classification}${item.status}48.png`}
                            style={{ width: "14px", marginRight: "0.1rem" }}
                          />
                          <Caption1 className="caseId">{item.case_id}</Caption1>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );
    },
  }),
];

const columnsForShortVersion = ["subject", "requested", "lastUpdated"];

export const AssignedDataList = ({
  list,
  compactView,
  onSelectRow,
  setShowLessColumns,
  showLessColums,
}) => {
  const refMap = useRef({});

  const columns = compactView
    ? compactColumns
    : showLessColums
    ? fullColumns.filter((column) => columnsForShortVersion.includes(column.columnId))
    : fullColumns;

  return (
    <DataGrid
      items={list}
      columns={columns}
      sortable
      getRowId={(item) => item.case_id}
      selectionMode="single"
      data-show-checkboxes="false"
      onSelectionChange={(e, row) => {
        onSelectRow(row);
        setShowLessColumns(true);
      }}
      resizableColumns
      columnSizingOptions={columnSizingOptions}
      className="scrollable-content"
      style={{
        maxHeight: "500px",
        padding: "10px",
      }}
    >
      <DataGridHeader>
        <DataGridRow
          selectionCell={{
            checkboxIndicator: { "aria-label": "Select all rows" },
          }}
        >
          {({ renderHeaderCell, columnId }, dataGrid) =>
            dataGrid.resizableColumns ? (
              <Menu openOnContext>
                <MenuTrigger>
                  <DataGridHeaderCell ref={(el) => (refMap.current[columnId] = el)}>
                    {renderHeaderCell()}
                  </DataGridHeaderCell>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuItem onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(columnId)}>
                      Keyboard Column Resizing
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            ) : (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )
          }
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody>
        {({ item, rowId }) => (
          <DataGridRow key={rowId} style={{ cursor: "pointer" }}>
            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};
