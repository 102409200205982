import { ElementNode, $isElementNode, $createParagraphNode, $getNodeByKey } from "lexical";

class EditableImageNode extends ElementNode {
  constructor(src, alt = "", width = "inherit", height = "inherit", key) {
    super(key);
    this.__src = src;
    this.__alt = alt;
    this.__width = width;
    this.__height = height;
  }

  static getType() {
    return "image";
  }

  static clone(node) {
    return new EditableImageNode(node.__src, node.__alt, node.__width, node.__height, node.__key);
  }

  createDOM(config) {
    // Crear un contenedor para la imagen
    const container = document.createElement("div");
    container.style.position = "relative";
    container.style.display = "inline-block";

    // Crear el elemento de imagen
    const element = document.createElement("img");
    element.src = this.__src;
    element.alt = this.__alt;
    element.style.width = this.__width;
    element.style.height = this.__height;
    element.style.maxWidth = "100%";

    // Crear el botón de eliminación
    const deleteButton = document.createElement("div");
    deleteButton.className = "remove-icon";
    deleteButton.style.display = "none"; // Oculto por defecto

    // Almacenar la referencia de la clave del nodo en el botón
    deleteButton.dataset.nodeKey = this.__key;

    // Agregar eventos de hover
    container.addEventListener("mouseenter", () => {
      deleteButton.style.display = "block";
    });

    container.addEventListener("mouseleave", () => {
      deleteButton.style.display = "none";
    });

    // Agregar imagen y botón al contenedor
    container.appendChild(element);
    container.appendChild(deleteButton);

    return container;
  }

  updateDOM() {
    return false;
  }

  // Ensure the node can be inserted into root
  insertNewAfter(selection, restoreSelection) {
    const paragraph = $createParagraphNode();
    this.insertAfter(paragraph, restoreSelection);
    return paragraph;
  }

  // Ensure compatibility with root node insertion
  canInsertAfter(node) {
    return $isElementNode(node);
  }

  canReplaceWith(node) {
    return $isElementNode(node);
  }

  static importJSON(serializedNode) {
    return $createImageNode(
      serializedNode.src,
      serializedNode.alt,
      serializedNode.width,
      serializedNode.height
    );
  }

  exportJSON() {
    return {
      type: "image",
      version: 1,
      src: this.__src,
      alt: this.__alt,
      width: this.__width,
      height: this.__height,
      children: [],
    };
  }

  // Override methods to handle image-specific behavior
  isInline() {
    return false;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  // Methods to interact with node properties
  getSource() {
    return this.__src;
  }

  getAlt() {
    return this.__alt;
  }

  setSource(src) {
    const self = this.getWritable();
    self.__src = src;
  }

  setAlt(alt) {
    const self = this.getWritable();
    self.__alt = alt;
  }

  // Ensure node can be selected and navigated
  selectNext(anchorOffset, focusOffset) {
    const parent = this.getParent();
    if (!parent) return this;

    const nextSibling = this.getNextSibling();
    if (nextSibling) {
      return nextSibling.select(anchorOffset, focusOffset);
    }

    return parent.selectEnd();
  }
}

function setupImageNodeDeletion(editor) {
  const handleDelete = (event) => {
    const deleteButton = event.target.closest(".remove-icon[data-node-key]");
    if (deleteButton) {
      const nodeKey = deleteButton.dataset.nodeKey;
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (node) {
          node.remove();
        }
      });
    }
  };

  // Delegación de eventos para manejar la eliminación
  document.addEventListener("click", handleDelete);

  // Opcional: devolver una función de limpieza si es necesario
  return () => {
    document.removeEventListener("click", handleDelete);
  };
}

// Helper function to create image node
function $createImageNode(src, alt = "", width = "inherit", height = "inherit") {
  return new EditableImageNode(src, alt, width, height);
}

export default EditableImageNode;
export { EditableImageNode, $createImageNode };
export { setupImageNodeDeletion };
