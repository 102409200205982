import React, { useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { createEditor } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  ImageDisplayNode,
  ImageDisplayNodePlugin,
  transformLexicalContent,
  ImageNodeDeserializer,
} from "./plugins/ImageDisplayPlugin";

function Placeholder({ text }) {
  return <div className="editor-placeholder">{text}</div>;
}

const theme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-tokenAttr",
    attr: "editor-tokenAttr",
    boolean: "editor-tokenProperty",
    builtin: "editor-tokenSelector",
    cdata: "editor-tokenComment",
    char: "editor-tokenSelector",
    class: "editor-tokenFunction",
    "class-name": "editor-tokenFunction",
    comment: "editor-tokenComment",
    constant: "editor-tokenProperty",
    deleted: "editor-tokenProperty",
    doctype: "editor-tokenComment",
    entity: "editor-tokenOperator",
    function: "editor-tokenFunction",
    important: "editor-tokenVariable",
    inserted: "editor-tokenSelector",
    keyword: "editor-tokenAttr",
    namespace: "editor-tokenVariable",
    number: "editor-tokenProperty",
    operator: "editor-tokenOperator",
    prolog: "editor-tokenComment",
    property: "editor-tokenProperty",
    punctuation: "editor-tokenPunctuation",
    regex: "editor-tokenVariable",
    selector: "editor-tokenSelector",
    string: "editor-tokenSelector",
    symbol: "editor-tokenProperty",
    tag: "editor-tokenProperty",
    url: "editor-tokenOperator",
    variable: "editor-tokenVariable",
  },
};

const editorConfig = (parsedContent) => {
  return {
    theme,
    onError(error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageDisplayNode,
    ],
    editable: false,
    editorState: parsedContent
      ? () => {
          const editor = createEditor();
          try {
            const processedContent = transformLexicalContent(parsedContent);
            editor.setEditorState(editor.parseEditorState(processedContent));
          } catch (error) {
            console.error("Error parsing editor state:", error);
          }
          return editor.getEditorState();
        }
      : undefined,
  };
};

function InitialContentPlugin({ initialContent }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialContent) {
      editor.update(() => {
        const parsedState = editor.parseEditorState(initialContent);
        editor.setEditorState(parsedState);
      });
    }
  }, [editor, initialContent]);

  return null;
}

export function RichEditorReadOnly({ placeholder = "Enter some text...", initialContent = null }) {
  const parsedContent = initialContent
    ? typeof initialContent === "string"
      ? JSON.parse(initialContent)
      : initialContent
    : null;

  return (
    <LexicalComposer initialConfig={editorConfig(parsedContent)}>
      <InitialContentPlugin initialContent={parsedContent} />
      <RichTextPlugin
        contentEditable={<ContentEditable className="editor-input readonly" />}
        placeholder={<Placeholder text={placeholder} />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ImageDisplayNodePlugin />
      <ImageNodeDeserializer />
    </LexicalComposer>
  );
}
