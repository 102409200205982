import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { TeamsFxContext } from "../../../Context";
import { Subtitle1, Caption1 } from "@fluentui/react-components";
import SankeyChart from "./Graphs/sankey";
import SunburstChart from "./Graphs/sunburst";
import SummaryCard from "./Graphs/summary";
import useAxios from "../../../lib/useAxios";

export function Dashboard() {
  const { themeString, organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [availableTeams, setAvailableTeams] = useState([]);
  const [orgTechnicians, setOrgTechnicians] = useState([]);

  useEffect(() => {
    const fetchTeamsNames = async () => {
      try {
        const { data } = await axiosInstance.get("/org/teams/names");
        if (data) {
          setAvailableTeams(data);
        }
      } catch (e) {
        console.debug(e);
      }
    };

    const pullCompanyTechnicians = async () => {
      try {
        const { data } = await axiosInstance.get("/org/technicians");
        if (data) {
          setOrgTechnicians(data);
        }
      } catch (e) {
        console.debug(e);
      }
    };

    fetchTeamsNames();
    pullCompanyTechnicians();
  }, []);

  return (
    <div className="app-body inline-content">
      <div className="flex-row" style={{ padding: "10px 0px" }}>
        <div style={{ display: "grid", gap: "5px" }}>
          <Subtitle1>Dashboard</Subtitle1>
          <Caption1 style={{ color: "var(--colorNeutralForeground4)" }}>
            Stats about your organization's incidents
          </Caption1>
        </div>
      </div>
      {availableTeams.length > 0 && orgTechnicians.length > 0 && (
        <div className="dashboard-container">
          <SummaryCard width="30" availableTeams={availableTeams} orgTechnicians={orgTechnicians} />
          <SunburstChart theme={themeString} width="40" />
          <SankeyChart theme={themeString} width="70" />
        </div>
      )}
    </div>
  );
}
