import React, { useState, useEffect, useContext } from "react";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import {
  Display,
  Body2,
  Button,
  Card,
  CardHeader,
  Body1,
  MenuGroup,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuGroupHeader,
  MenuList,
  MenuItemRadio,
  MenuItem,
  MenuDivider,
  Persona,
} from "@fluentui/react-components";
import {
  bundleIcon,
  Dismiss12Regular,
  MoreHorizontal20Regular,
  PeopleCommunityFilled,
  PeopleCommunityRegular,
} from "@fluentui/react-icons";

const FormattedText = ({ text }) => {
  if (!text) return null;

  const processText = (text) => {
    const parts = text.split(/(<.*?>)/g);
    return parts.map((part, index) => {
      const match = part.match(/<(.*?)>/);

      if (match) {
        const content = match[1];
        return (
          <span key={index} className={`tag-status-open pill-tag`}>
            {content}
          </span>
        );
      }
      return part;
    });
  };

  return <Body2 className="text-base">{processText(text)}</Body2>;
};

const TeamIcon = bundleIcon(PeopleCommunityFilled, PeopleCommunityRegular);

const SummaryCard = ({ availableTeams, orgTechnicians, width = "40" }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState({ period: ["30"] });
  const [selectedGroup, setSelectedGroup] = useState();
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getResolutionRateData(selectedPeriod.period, selectedGroup);
  }, [selectedPeriod, selectedGroup]);

  const getResolutionRateData = async (period, selectedGroup) => {
    try {
      const groupParam = selectedGroup?.techId
        ? `&technician=${selectedGroup.techId}`
        : selectedGroup?.teamId
        ? `&team=${selectedGroup.teamId}`
        : "";
      const { data } = await axiosInstance.get(
        `/dash/resolution-rate?period=${period}${groupParam}`
      );
      setSummaryData(data);
    } catch (e) {
      console.error("Error loading incidents chart:", e);
    } finally {
      setLoading(false);
    }
  };

  const onSelectPeriod = (e, { name, checkedItems }) => {
    setSelectedPeriod((s) => ({ ...s, [name]: checkedItems }));
  };

  const onSelectGroup = (e, { name, checkedItems }) => {
    setSelectedGroup(() => ({ [name]: checkedItems }));
  };

  return (
    <Card className={`dashboard-card card-${width}`}>
      <CardHeader
        header={
          <Body1>
            <b>Resolution rate</b>
          </Body1>
        }
        description={
          selectedGroup && (
            <Button
              size="small"
              shape="circular"
              appearance="primary"
              icon={<Dismiss12Regular />}
              iconPosition="after"
              onClick={() => setSelectedGroup()}
              style={{ marginTop: "1em" }}
            >
              {selectedGroup.techId
                ? orgTechnicians.find((t) => t.userid == selectedGroup.techId)?.display_name
                : availableTeams.find((t) => t.id == selectedGroup.teamId)?.name}
            </Button>
          )
        }
        action={
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="transparent" icon={<MoreHorizontal20Regular />}></MenuButton>
            </MenuTrigger>
            <MenuPopover>
              <MenuGroup>
                <MenuGroupHeader>Period</MenuGroupHeader>
                <MenuList checkedValues={selectedPeriod} onCheckedValueChange={onSelectPeriod}>
                  <MenuItemRadio name="period" value="7">
                    Last 7 Days
                  </MenuItemRadio>
                  <MenuItemRadio name="period" value="30">
                    Last 30 Days
                  </MenuItemRadio>
                  <MenuItemRadio name="period" value="90">
                    Last 90 Days
                  </MenuItemRadio>
                  <MenuItemRadio name="period" value="ytd">
                    Year to Date (YTD)
                  </MenuItemRadio>
                </MenuList>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>Filter</MenuGroupHeader>
                <MenuList>
                  <Menu
                    checkedValues={selectedGroup?.teamId ? selectedGroup : null}
                    onCheckedValueChange={onSelectGroup}
                  >
                    <MenuTrigger disableButtonEnhancement>
                      <MenuItem>By Team</MenuItem>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        {availableTeams.map((team) => (
                          <MenuItemRadio
                            key={team.id}
                            icon={<TeamIcon />}
                            name="teamId"
                            value={team.id}
                          >
                            {team.name}
                          </MenuItemRadio>
                        ))}
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                  <Menu
                    checkedValues={selectedGroup?.techId ? selectedGroup : null}
                    onCheckedValueChange={onSelectGroup}
                  >
                    <MenuTrigger disableButtonEnhancement>
                      <MenuItem>By specific Technician</MenuItem>
                    </MenuTrigger>

                    <MenuPopover>
                      <MenuList>
                        {orgTechnicians.map((technician) => (
                          <MenuItemRadio
                            key={technician.userid}
                            name="techId"
                            value={technician.userid}
                          >
                            <Persona
                              size="extra-small"
                              name={technician.display_name}
                              primaryText={technician.display_name}
                              avatar={{ color: "colorful" }}
                            />
                          </MenuItemRadio>
                        ))}
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                </MenuList>
              </MenuGroup>
            </MenuPopover>
          </Menu>
        }
      />

      <div style={{ width: "100%", height: "100%", minHeight: "300px" }}>
        {!loading && (
          <div className="flex-column">
            <Display>{summaryData.rate}%</Display>
            <FormattedText text={summaryData.summary} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default SummaryCard;
