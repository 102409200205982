import { useState, useContext, useEffect, Fragment } from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  InlineDrawer,
  Button,
  Title3,
  Subtitle2,
  Caption1,
  Body1,
  Badge,
  TabList,
  Tab,
  Card,
  CardHeader,
  AvatarGroup,
  AvatarGroupItem,
  MenuButton,
  Persona,
  ProgressBar,
  Tag,
  Tooltip,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  useId,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  ArrowMaximize24Filled,
  ArrowMinimize24Filled,
  CircleFilled,
  FlashRegular,
  NotePin16Regular as ResolutionNotesIcon,
} from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../../Context";
import { authentication } from "@microsoft/teams-js";
import TagContainer from "../../../../components/TagContainer/TagContainer";
import { AttachmentList } from "../../../../components/AttachmentList";
import AIGeneratedSummary from "../../../../components/AIGeneratedSummary";
import Timeline from "../../../../components/Timeline";
import useAxios from "../../../../lib/useAxios";
import NewComment from "../../../../components/NewComment";
import CommentReadOnly from "../../../../components/CommentReadOnly";
import { RichEditorReadOnly } from "../../../../components/RichEditorReadOnly";
import { MessageGroup } from "../../../../components/MessageGroup";
import { IncidentActions } from "../../../../components/IncidentActions";
import { getHumanDate, getFullDateTime, getAuthName } from "../../../../lib/common";
import { app } from "@microsoft/teams-js";

const actionMessages = {
  change_priority: "Priority has been changed successfully",
  reassign: "The [case] has been reassigned successfully",
  close: "The [case] has been closed successfully",
  reopen: "The [case] has been reopened successfully",
  hold: "The [case] has been put on hold",
  resume: "The [case] has been resumed",
};

export const IncidentDetails = ({
  open,
  setOpen,
  position,
  setShowLessColumns,
  onSelectRow,
  selectedCase,
}) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [availableTeams, setAvailableTeams] = useState([]);
  const [orgTechnicians, setOrgTechnicians] = useState([]);
  const [selectedTab, setSelectedTab] = useState("comments");
  const [isMaximized, setIsMaximized] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [rawContent, setRawContent] = useState();
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [errorOnMessage, setErrorOnMessage] = useState();

  const toasterId = useId("actionsMessages");
  const { dispatchToast } = useToastController(toasterId);

  const fetchCaseDetails = async () => {
    setLoadingData(true);
    try {
      const { data } = await axiosInstance.get(`/incidents/details/${selectedCase.case_id}`);
      if (data) {
        setRawContent(data.raw_content);
        setComments(data.comments);
        setHistory(data.history);
        setAttachments(data.attachments);
      }
      if (availableTeams.length === 0) {
        await fetchTeamsNames();
      }
      if (orgTechnicians.length === 0) {
        await pullCompanyTechnicians();
      }
    } catch (e) {
      console.debug(e);
    } finally {
      setLoadingData(false);
    }
  };

  const fetchTeamsNames = async () => {
    try {
      const { data } = await axiosInstance.get("/org/teams/names");
      if (data) {
        setAvailableTeams(data);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  const pullCompanyTechnicians = async () => {
    try {
      const { data } = await axiosInstance.get("/org/technicians");
      if (data) {
        setOrgTechnicians(data);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  const sendNotificationToUser = async (recipient_ms_id, title, preview_text) => {
    try {
      const sso_token = await authentication.getAuthToken();
      await axiosInstance.post("/msteams/notify", {
        sso_token,
        recipient_ms_id,
        title,
        preview_text,
      });
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    if (selectedTab !== "comments") setSelectedTab("comments");
    fetchCaseDetails();
  }, [selectedCase]);

  const addCommentToList = (jsonContent) => {
    const newComment = {
      by_user_name: getAuthName(),
      comment_date: new Date().toISOString(),
      raw_comment: jsonContent,
      isLoading: true,
    };
    setComments((prevComments) => [...prevComments, newComment]);
  };

  const updateCommentStatus = (processed, details) => {
    fetchCaseDetails();
    if (!processed) {
      setErrorOnMessage({
        id: "500",
        intent: "error",
        title: "Oops!",
        body: `Something went wrong sending the comment: ${details}`,
        dispatchAction: () => setErrorOnMessage(undefined),
      });
      setTimeout(() => {
        setErrorOnMessage(undefined);
      }, 10000);
    } else {
      if (selectedCase.assigned_msid) {
        // Send a notification to requester if differente from the current user, if not send to the assigned
        app.getContext().then((context) => {
          const currentUserMSId = context.user?.id;
          const prefix = ["Completed", "Canceled"].includes(selectedCase.status) ? `[Closed] ` : "";
          sendNotificationToUser(
            currentUserMSId === selectedCase.requester_msid
              ? selectedCase.assigned_msid
              : selectedCase.requester_msid,
            `💬 ${prefix}${selectedCase.subject}`,
            details
          );
        });
      }
    }
  };

  const handleRefreshAfterAction = (action) => {
    fetchCaseDetails();
    // Notify only some kind of actions
    if (
      ["reassign", "close", "reopen", "hold", "resume"].includes(action.name) &&
      action.notifications.length > 0
    ) {
      action.notifications.forEach((notification) => {
        sendNotificationToUser(notification.recipient, notification.title, notification.preview);
      });
    }
    // Ok message
    const message = actionMessages[action.name];
    if (message) {
      dispatchToast(
        <Toast>
          <ToastTitle>{message.replace("[case]", selectedCase.classification)}</ToastTitle>
        </Toast>,
        { intent: "success", duration: 2800, position: "top" }
      );
    }
  };

  const createdDatetime = getFullDateTime(selectedCase.created_at, true);

  return (
    <InlineDrawer
      open={open}
      position={position}
      size={isMaximized ? "full" : "medium"}
      className="incident-details"
      style={{ minHeight: "100em" }}
    >
      <Toaster toasterId={toasterId} />
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <>
              <Button
                appearance="subtle"
                aria-label="Maximize"
                icon={isMaximized ? <ArrowMinimize24Filled /> : <ArrowMaximize24Filled />}
                onClick={() => setIsMaximized(!isMaximized)}
              />
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => {
                  onSelectRow(null);
                  setOpen(false);
                  setTimeout(() => {
                    setShowLessColumns(false);
                    if (isMaximized) {
                      setIsMaximized(false);
                    }
                  }, 350);
                }}
              />
            </>
          }
        >
          <Subtitle2 style={{ fontSize: "var(--fontSizeBase300)" }}>
            Incident details <Badge appearance="ghost">#{selectedCase.case_id}</Badge>
          </Subtitle2>
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <Title3 style={{ width: "100%" }}>{selectedCase.subject}</Title3>
        <div className="flex-row" style={{ padding: "20px 0px 25px 0px" }}>
          <TagContainer tags={selectedCase.tags} />
          {loadingData ? (
            <MenuButton icon={<FlashRegular />}>Actions</MenuButton>
          ) : (
            <IncidentActions
              incident={selectedCase}
              availableTeams={availableTeams}
              orgTechnicians={orgTechnicians}
              onRefresh={handleRefreshAfterAction}
            />
          )}
        </div>
        <div className="item mb-1">
          <Caption1 style={{ marginBottom: "1em" }}>Created at</Caption1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Caption1 className="incident-date">
              {createdDatetime[0]} {createdDatetime[1]}
            </Caption1>
          </div>
        </div>
        {selectedCase.summary && selectedCase.summary.length > 0 && (
          <AIGeneratedSummary content={selectedCase.summary} caseId={selectedCase.case_id} />
        )}
        {selectedCase.completed_notes && selectedCase.completed_notes.length > 0 && (
          <div className="mt-4">
            <Card
              appearance="outline"
              size="small"
              className="notes-content"
              style={{ maxWidth: "700", width: "100%" }}
            >
              <span className="notes">
                <ResolutionNotesIcon /> {selectedCase.completed_notes}
              </span>
            </Card>
          </div>
        )}

        {/* Details status */}
        <div className="items-detail">
          <div className="item">
            <Caption1 style={{ marginBottom: "1em" }}>Assigned to</Caption1>
            <Persona
              size="medium"
              name={selectedCase.assigned_name || "Unassigned"}
              avatar={{ color: "colorful" }}
              secondaryText={selectedCase.team_name || ""}
            />
          </div>

          {selectedCase.status && (
            <div className="item">
              <Caption1 style={{ marginBottom: "1em" }}>Status</Caption1>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag
                  shape="circular"
                  size="small"
                  appearance="brand"
                  className={`tag-status-${selectedCase.status}`}
                >
                  {selectedCase.status}
                </Tag>
              </div>
            </div>
          )}

          {selectedCase.priority && (
            <div className="item">
              <Caption1 style={{ marginBottom: "1em" }}>Priority</Caption1>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircleFilled className={`dot-priority dot-priority-${selectedCase.priority}`} />
                <Caption1>{selectedCase.priority}</Caption1>
              </div>
            </div>
          )}
        </div>

        <div className="tabList">
          <TabList
            selectedValue={selectedTab}
            onTabSelect={(_, data) => setSelectedTab(data.value)}
          >
            <Tab id="Description" value="comments">
              Description
            </Tab>
            <Tab id="History" value="history">
              History
            </Tab>
          </TabList>
          <div>
            {selectedTab === "comments" && (
              <div>
                <Card style={{ marginTop: "2em", maxWidth: "700" }}>
                  <CardHeader
                    image={
                      <Persona
                        size="medium"
                        name={selectedCase.requester_name}
                        primaryText=""
                        avatar={{ color: "colorful" }}
                      />
                    }
                    header={
                      <Body1>
                        <b>{selectedCase.requester_name}</b>
                      </Body1>
                    }
                    description={
                      <Caption1>
                        {"Requested"} ·{" "}
                        <Tooltip
                          content={getFullDateTime(selectedCase.created_at)}
                          withArrow={true}
                          relationship="label"
                        >
                          <span>{getHumanDate(selectedCase.created_at)}</span>
                        </Tooltip>
                      </Caption1>
                    }
                  />
                  {(selectedCase.raw_content || rawContent) && (
                    <RichEditorReadOnly
                      initialContent={JSON.stringify(selectedCase.raw_content || rawContent)}
                    />
                  )}
                  <AttachmentList list={attachments.filter((a) => a.commentId === null)} />
                </Card>

                {/* participans -> {comments.length > 0 && (
                        <div style={{ float: "right" }}>
                          {comments.length > 0 &&
                            comments.map((comment, index) => (
                              <AvatarGroup key={index} layout="stack" size={20}>
                                <AvatarGroupItem
                                  name={comment.by_user_name}
                                  key={comment.by_user_name}
                                />
                              </AvatarGroup>
                            ))}
                        </div>
                      )} */}
                {comments.length > 0 &&
                  comments.map((comment, index) => (
                    <Fragment key={index}>
                      <Card
                        key={index}
                        appearance="filled"
                        size="small"
                        style={{ maxWidth: "700" }}
                      >
                        <CommentReadOnly
                          comment={comment}
                          attachments={attachments.filter(
                            (a) => a.commentId === comment.comment_id
                          )}
                          isLoading={comment.isLoading || false}
                        />
                        {errorOnMessage && index === comments.length - 1 && (
                          <div style={{ paddingInline: 25 }}>
                            <MessageGroup messages={[errorOnMessage]} />
                          </div>
                        )}
                      </Card>
                    </Fragment>
                  ))}
                <Card appearance="filled" size="small" style={{ maxWidth: "700" }}>
                  <NewComment
                    case_id={selectedCase.case_id}
                    onCommentSent={addCommentToList}
                    onCommentProcessed={updateCommentStatus}
                  />
                </Card>
              </div>
            )}
            {selectedTab === "history" && (
              <>
                {loadingData ? (
                  <ProgressBar />
                ) : history.length > 0 ? (
                  <div style={{ paddingTop: 30 }}>
                    <Timeline history={history} />
                  </div>
                ) : (
                  <p style={{ textAlign: "center" }}>No historical elements</p>
                )}
              </>
            )}
          </div>
        </div>
      </DrawerBody>
    </InlineDrawer>
  );
};
