import React, { useState, useEffect, useMemo, useContext } from "react";
import AnnouncementSmall from "../../../../components/AnnouncementSmall";
import {
  Card,
  CardHeader,
  Body1,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItemRadio,
  MenuGroupHeader,
  MenuGroup,
  MenuDivider,
  MenuItemCheckbox,
} from "@fluentui/react-components";
import {
  bundleIcon,
  Calendar20Regular,
  Calendar20Filled,
  MoreHorizontal20Regular,
} from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import Plot from "react-plotly.js";

const SankeyChart = ({ theme = "dark", width = "60" }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState({ period: ["90"] });
  const [selectedStatus, setSelectedStatus] = useState({ status: ["all"] });
  const [disablePeriod, setDisablePeriod] = useState(false);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCasesTeamsData(selectedStatus.status, selectedPeriod.period);
  }, [selectedPeriod, selectedStatus]);

  const getCasesTeamsData = async (status, period) => {
    try {
      const { data } = await axiosInstance.get(
        `/dash/teams-assignments?status=${status}&period=${period}`
      );
      setChartData(data);
    } catch (e) {
      console.error("Error loading incidents chart:", e);
    } finally {
      setLoading(false);
    }
  };

  const lightTheme = {
    font: { color: "#000000" },
    node: {
      line: { color: "#000000" },
      link: Array(54).fill("rgba(0,0,0,0.2)"),
      nodes: [
        "#e88d54", // Incidents
        "#ffb74d", // Requests
        "#af0000", // Critical
        "#E91E63", // High
        "#f2c661", // moderate
        "#a9d3f2", // Normal
        "#66ffff",
        "#ff66ff",
        "#cc9966",
        "#ff99cc",
        "#b3b3b3",
        "#cccc66",
        "#66cccc",
        "#6666cc",
        "#cc6666",
      ],
    },
  };

  const darkTheme = {
    font: { color: "#ffffff" },
    node: {
      line: { color: "#ffffff" },
      link: Array(54).fill("rgba(255,255,255,0.2)"),
      nodes: [
        "#e88d54", // Incidents
        "#ffb74d", // Requests
        "#af0000", // Critical
        "#E91E63", // High
        "#f2c661", // moderate
        "#a9d3f2", // Normal
        "#66ffff",
        "#ff66ff",
        "#cc9966",
        "#ff99cc",
        "#b3b3b3",
        "#cccc66",
        "#66cccc",
        "#6666cc",
        "#cc6666",
      ],
    },
  };

  const currentTheme = ["default", "light"].includes(theme) ? lightTheme : darkTheme;

  const defaultLayout = {
    autosize: true,
    margin: { l: 0, r: 0, b: 0, t: 0, pad: 4 },
    font: { size: 8 },
    paper_bgcolor: "transparent",
    ...currentTheme,
  };

  const convertJsonToSankeyData = (jsonData) => {
    const types = ["incident", "request"];
    const priorities = ["critical", "high", "moderate", "normal"];
    const teams = jsonData.map((item) => item.team);

    const nodeLabels = [...types, ...priorities, ...teams];

    const linkMap = new Map();

    const getKey = (source, target) => `${source}-${target}`;

    jsonData.forEach((teamData) => {
      const teamIndex = nodeLabels.indexOf(teamData.team);

      teamData.tickets.forEach((ticket) => {
        const typeIndex = nodeLabels.indexOf(ticket.type);
        const priorityIndex = nodeLabels.indexOf(ticket.priority);

        // Link from type to priority
        const typeToPriorityKey = getKey(typeIndex, priorityIndex);
        if (linkMap.has(typeToPriorityKey)) {
          linkMap.get(typeToPriorityKey).value += ticket.count;
        } else {
          linkMap.set(typeToPriorityKey, {
            source: typeIndex,
            target: priorityIndex,
            value: ticket.count,
          });
        }

        // Link from priority to team
        const priorityToTeamKey = getKey(priorityIndex, teamIndex);
        if (linkMap.has(priorityToTeamKey)) {
          linkMap.get(priorityToTeamKey).value += ticket.count;
        } else {
          linkMap.set(priorityToTeamKey, {
            source: priorityIndex,
            target: teamIndex,
            value: ticket.count,
          });
        }
      });
    });

    const links = {
      source: [],
      target: [],
      value: [],
    };

    linkMap.forEach((link) => {
      links.source.push(link.source);
      links.target.push(link.target);
      links.value.push(link.value);
    });

    return [
      {
        type: "sankey",
        length: jsonData.length,
        orientation: "h",
        valueformat: ".0f",
        /* valuesuffix: "dale", */
        node: {
          pad: 15,
          thickness: 20,
          line: currentTheme.node.line,
          label: nodeLabels,
          color: currentTheme.node.nodes,
          align: "left",
        },
        link: {
          ...links,
          color: Array(links.source.length).fill(currentTheme.node.link[0]),
        },
      },
    ];
  };

  const sankeyData = useMemo(() => {
    try {
      return convertJsonToSankeyData(chartData);
    } catch (error) {
      console.error("Error converting data for incidents chart:", error);
      return null;
    }
  }, [chartData, theme]);

  if (!sankeyData) {
    return <div>Error loading chart data</div>;
  }

  const onSelectPeriod = (e, { name, checkedItems }) => {
    setSelectedPeriod((s) => ({ ...s, [name]: checkedItems }));
  };

  const onSelectStatus = (e, { name, checkedItems }) => {
    setSelectedStatus((s) => ({ ...s, [name]: checkedItems }));
    if (checkedItems.length === 1 && checkedItems[0] === "open") {
      setDisablePeriod(true);
    } else if (disablePeriod) {
      setDisablePeriod(false);
    }
  };

  return (
    <Card className={`dashboard-card card-${width}`}>
      <CardHeader
        header={
          <Body1>
            <b>Incidents assigned to Teams</b>
          </Body1>
        }
        action={
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="transparent" icon={<MoreHorizontal20Regular />}></MenuButton>
            </MenuTrigger>
            <MenuPopover>
              <MenuGroup>
                <MenuGroupHeader>Period</MenuGroupHeader>
                <MenuList checkedValues={selectedPeriod} onCheckedValueChange={onSelectPeriod}>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="7">
                    Last 7 Days
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="30">
                    Last 30 Days
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="90">
                    Last 90 Days
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="ytd">
                    Year to Date (YTD)
                  </MenuItemRadio>
                  <MenuItemRadio disabled={disablePeriod} name="period" value="1826">
                    Last 5 Years
                  </MenuItemRadio>
                </MenuList>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>Incident status</MenuGroupHeader>
                <MenuList checkedValues={selectedStatus} onCheckedValueChange={onSelectStatus}>
                  <MenuItemRadio name="status" value="open">
                    Open
                  </MenuItemRadio>
                  <MenuItemRadio name="status" value="closed">
                    Closed
                  </MenuItemRadio>
                  <MenuItemRadio name="status" value="all">
                    All
                  </MenuItemRadio>
                </MenuList>
              </MenuGroup>
            </MenuPopover>
          </Menu>
        }
      />
      <div style={{ width: "100%", height: "100%", minHeight: "300px" }}>
        {!loading &&
          (chartData.length === 0 ? (
            <AnnouncementSmall
              imgSrc="dashboard.png"
              imgAlt="No incident data available"
              imgWidth="15em"
              title="No incident data available"
              subtitle="Come back later or update filtering"
            />
          ) : (
            <Plot
              data={sankeyData}
              layout={defaultLayout}
              config={{ displayModeBar: false, responsive: true }}
              style={{ width: "100%", height: "100%" }}
            />
          ))}
      </div>
    </Card>
  );
};

export default SankeyChart;
